<template>
  <div id="app">
    <transition :name="$route.meta.transitionName">
      <router-view />
    </transition>
  </div>
</template>

<script>

export default {
  data() {
    return {}
  }
}
</script>


<style lang="less">
html>body{
  background-color: #eee;
  min-width: 320px;
  min-height: 600px;
}
#app{
  max-width: 600px;
  height: 100%;
  margin: 0 auto;
  background-color: #f9f9f9;
}
.flex{
  display: flex;
  &.column{
    flex-direction: column;
  }
  &.between{
    justify-content: space-between;
  }
  &.around{
    justify-content: space-around;
  }
  &.center{
    justify-content: center;
  }
}
.no-shrink{
  flex-shrink: 0;
}

.g_sort_header{
  border-bottom: 1px solid #ccc;
  background-color: #fff;
  .sort.up,.sort.down{
    color: #2185d0;
  }
  .item{
    display: inline-block;
    width: 33%;
    padding: 18px 10px;
    font-weight: bold;
    font-size: 14px;
  }
}

.slide-in-right-enter-active,
.slide-in-right-leave-active {
  transition: all 0.3s;
}

.slide-in-right-enter {
  transform: translateX(100%);
}

.slide-in-right-leave-to {
  transform: translateX(-100%);
}

.article-page{
  .content{
    height: calc(100vh - 90px);
    width: 90%;
    margin: 0 auto;
    margin-top: 10px;
    overflow-y: auto;
    background-color: #fff;
    box-shadow: 1px 1px 3px #eee;
    .title{
      font-size: 18px;
      text-align: center;
      font-weight: bold;
      margin-top: 20px;
    }
    .cont{
      padding: 20px;
      p{
        line-height: 1.8;
        margin-bottom: 22px;
      }
    }
  }
}
</style>

<template>
  <transition name="message-fade">
    <div class="message" :class="stateObject" v-show="isVisible" :style="'top:'+offset">
      {{ message }}
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      isVisible: false,
      isError: false,
      isSuccess: false,
      offset: '',
      message: ''
    };
  },
  computed: {
    stateObject() {
      return {
        error: this.isError,
        success: this.isSuccess
      };
    }
  },
  methods: {
    show(message,offset = "30%",duration = 2500) {
      this.message = message;
      this.isVisible = true;
      this.offset = offset;
      setTimeout(() => {
        this.hide();
      }, duration);
    },
    hide() {
      this.isVisible = false;
      this.isError = false;
      this.isSuccess = false;
    }
  }
};
</script>

<style scoped>
.message{
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(1,1,1,0.7);
  color: #fff;
  padding: 10px 20px;
  border-radius: 4px;
  z-index: 9999;
}
.message-fade-enter-active,
.message-fade-leave-active {
  transition: opacity 0.5s;
}

.message-fade-enter,
.message-fade-leave-to {
  opacity: 0;
}
</style>

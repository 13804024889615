import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "semantic-ui-css/semantic.min.css";
import MyToast from './components/MyToast';
import MyLoading from './components/MyLoading';

Vue.config.productionTip = false;
Vue.prototype.$toast = new Vue(MyToast).$mount();
Vue.prototype.$loading = new Vue(MyLoading).$mount();
document.body.appendChild(Vue.prototype.$toast.$el);
document.body.appendChild(Vue.prototype.$loading.$el);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    isLoggedIn: false, // 初始值为未登录
  },
  getters: {
    isLoggedIn: (state) => state.isLoggedIn, // getter 方法返回 state.isLoggedIn 属性
  },
  mutations: {
    setLoggedIn(state, value) {
      state.isLoggedIn = value; // mutation 用于更新 state.isLoggedIn 的值
    },
  },
  actions: {
    updateLoggedIn({ commit }, value) {
      commit("setLoggedIn", value); // action 委托 mutation 更新 state.isLoggedIn 的值
    },
  },
  modules: {},
});

export default store;

import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    redirect:"/home", //指定默认子路由
    component: HomeView,
    children:[
      {
        path: "/home",
        name: "home",
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/AssetsView.vue"),
      },
      {
        path: "/transaction",
        name: "transaction",
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/TransactionView.vue"),
      },
      {
        path: "/otc",
        name: "otc",
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/OtcView.vue"),
      },
      {
        path: "/personal",
        name: "personal",
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/PersonalView.vue"),
      },
    ]
  },
  {
    path: "/detail",
    name: "detail",
    component: () =>
      import(/* webpackChunkName: "other" */ "../views/CurrencyDetail.vue"),
    meta: {
      transitionName: 'slide-in-right'
    }
  },
  {
    path: "/withdraw",
    name: "Withdraw",
    component: () =>
      import(/* webpackChunkName: "other" */ "../views/WithdrawView.vue"),
    meta: {
      transitionName: 'slide-in-right'
    }
  },
  {
    path: "/deposit",
    name: "deposit",
    component: () =>
      import(/* webpackChunkName: "other" */ "../views/DepositView.vue"),
    meta: {
      transitionName: 'slide-in-right'
    }
  },
  {
    path: "/wallet",
    name: "wallet",
    component: () =>
      import(/* webpackChunkName: "other" */ "../views/WalletView.vue")
  },
  {
    path: "/orderRecord",
    name: "orderRecord",
    component: () =>
      import(/* webpackChunkName: "other" */ "../views/OrderRecord.vue"),
    meta: {
      transitionName: 'slide-in-right'
    }
  },
  {
    path: "/termsOfService",
    name: "termsOfService",
    component: () =>
      import(/* webpackChunkName: "other" */ "../views/TermsOfService.vue"),
    meta: {
      transitionName: 'slide-in-right'
    }
  },
  {
    path: "/disclaimer",
    name: "disclaimerView",
    component: () =>
      import(/* webpackChunkName: "other" */ "../views/DisclaimerView.vue"),
    meta: {
      transitionName: 'slide-in-right'
    }
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "other" */ "../views/LoginView.vue"),
  },
];

const router = new VueRouter({
  routes,
});

//路由守卫
router.beforeEach((to, from, next) => {
  const isLoggedIn = store.getters.isLoggedIn; // 从vuex中获取登录状态

  if (to.name !== "Login" && !isLoggedIn) {
    next({ name: "Login" }); // 重定向到登录页
  } else {
    next(); // 允许继续访问目标路由
  }
});

export default router;

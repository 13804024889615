<template>
  <div class="home">
    <router-view></router-view>
    <div class="footer flex around">
      <router-link to="/home" class="item" exact active-class="active">
        <div class="icon" style="padding:0 0.2em;">
          <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64" height="64"><path d="M895.488 192.512L270.848 68.096a44.8 44.8 0 0 0-8.192-1.024c-69.12 0-126.976 58.368-126.976 126.976v67.584C73.728 270.848 25.6 324.096 25.6 387.584v442.368a126.976 126.976 0 0 0 126.976 126.976h718.336a126.976 126.976 0 0 0 126.976-126.976V320.512c0.512-62.464-36.864-110.08-102.4-128zM259.072 155.648l526.848 104.96H223.744v-66.56c0-19.456 15.872-36.352 35.328-38.4z m612.352 712.704H152.576a38.912 38.912 0 0 1-38.912-38.912V387.584c0-20.48 17.92-38.4 37.888-38.912H909.824v480.768c0 21.504-17.408 38.912-38.4 38.912z" ></path><path d="M203.264 569.856c-20.48 21.504-20.48 56.832 0 78.848 21.504 20.48 56.832 20.48 78.336 0 21.504-21.504 21.504-56.832 0-78.848-21.504-21.504-56.832-21.504-78.336 0z" ></path></svg>
        </div>
        <div class="title">Assets</div>
      </router-link>
      <router-link to="/transaction" class="item" exact active-class="active">
        <div class="icon">
          <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64" height="64"><path d="M736 192a160 160 0 0 1 159.914667 154.816L896 352v341.333333a160 160 0 0 1-154.816 159.914667L736 853.333333h-426.666667a160 160 0 0 1-159.914666-154.816L149.333333 693.333333v-341.333333a160 160 0 0 1 154.816-159.914667L309.333333 192h426.666667z m0 64h-426.666667a96 96 0 0 0-95.893333 91.477333L213.333333 352v341.333333a96 96 0 0 0 91.477334 95.893334L309.333333 789.333333h426.666667a96 96 0 0 0 95.893333-91.477333L832 693.333333v-55.04h-97.706667a115.626667 115.626667 0 0 1-115.541333-110.976L618.666667 522.666667a115.626667 115.626667 0 0 1 110.976-115.541334l4.650666-0.085333 97.706667-0.021333V352a96 96 0 0 0-91.477333-95.893333L736 256zM832 471.04h-97.706667a51.626667 51.626667 0 0 0-51.178666 44.757333l-0.341334 3.477334L682.666667 522.666667a51.626667 51.626667 0 0 0 48.213333 51.52l3.413333 0.106666H832v-103.253333zM437.333333 330.666667a32 32 0 0 1 3.072 63.850666l-3.072 0.149334h-128a32 32 0 0 1-3.072-63.850667l3.072-0.149333h128z"></path></svg>
        </div>
        <div class="title">Transaction</div>
      </router-link>
      <router-link to="/otc" class="item" exact active-class="active">
        <div class="icon" style="padding:0 0.3em;">
          <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64" height="64"><path d="M445.4 64.2c10 33.8-9.2 69.6-43 79.6C243.6 191.2 128 338.2 128 512c0 212 172 384 384 384s384-172 384-384c0-173.8-115.6-320.8-274.2-368.2-33.8-10-53.2-45.8-43-79.6s45.8-53.2 79.6-43C869.8 84.2 1024 280 1024 512c0 282.8-229.2 512-512 512S0 794.8 0 512C0 280 154.2 84.2 365.8 21.2c33.8-10 69.6 9.2 79.6 43z"></path></svg>
        </div>
        <div class="title">OTC</div>
      </router-link>
      <router-link to="/personal" class="item" exact active-class="active">
        <div class="icon">
          <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64" height="64"><path d="M505 597.9c57.3-36.8 95.2-101 95.2-174.2 0-114.2-92.5-206.8-206.8-206.8s-206.8 92.5-206.8 206.8c0 73.1 38 137.4 95.2 174.2-108 42.3-184.3 144.8-184.3 268.3h60.7c7.4-123.6 110-216 235.4-216s228 92.4 235.4 216h60.5c-0.1-123.5-76.3-226-184.5-268.3zM243.9 423.8c0-81.6 66.1-147.8 147.8-147.8s147.8 66.1 147.8 147.8-66.1 147.8-147.8 147.8-147.8-66.2-147.8-147.8z"></path><path d="M549.7 217H571l0.2-59.1h295.7c32.9 0 59.6 26.7 59.6 59.6v589.2c0 32.9-26.7 59.6-59.6 59.6H748.5V807H833c19 0 34.4-15.4 34.4-34.4V251.4c0-19-15.4-34.4-34.4-34.4H571.1"></path><path d="M630.1 276.1h177.3v59.1H630.1v-59.1z m0 118.2h177.3v59.1H630.1v-59.1z m0 118.2h177.3v59.1H630.1v-59.1z m59.1 118.2h118.2v59.1H689.2v-59.1z"></path></svg>
        </div>
        <div class="title">Personal</div>
      </router-link>
    </div>
  </div>
</template>

<script>

export default {
  name: "HomeView",
  components: {
  },
};
</script>
<style lang="less" scoped>
.home{
  position: relative;
  width: 100%;
  height: 100%;
}
.footer{
  position: absolute;
  z-index: 1001;
  bottom: 0;
  background-color: #fff;
  width: 100%;
  left: 0;
  right: 0;
  .item{
    flex-grow: 1;
    text-align: center;
    padding: 6px 0;
    &.active{
      .icon>svg>path{
        fill: #333;
      }
      .title{
        color: #333;
      }
    }
    .icon{
      width: 2em;
      margin: 0 auto;
      svg{
        display: block;
        width: 100%;
        height: 2em;
        path{
          fill: #999;
        }
      }
    }
    .title{
      color: #999;
      height: 16px;
      line-height: 16px;
    }
  }
}
</style>